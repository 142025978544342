import { FormProvider, UseFormReturn } from 'react-hook-form';
import * as yup from 'yup';
import ValidationSchemaProvider from '@/providers/ValidationSchemaProvider';
import { FC } from 'react';
import React from 'react';
import { useState } from 'react';
import { useNavigationBlocker } from './hooks/useNavigationBlocker';
import { useBeforeUnloadHandler } from './hooks/useBeforeUnloadHandler';
import { ConfirmChangesModal } from './components/ConfirmChangesModal';
import { UnsavedChangesModal } from './components/UnsavedChangesModal';

interface FormWrapperProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  form: UseFormReturn<any, any>;
  schema: yup.Schema;
  children: React.ReactElement<HTMLFormElement>;
  preventNavigationIfDirty?: boolean;
  initialData?: Record<string, unknown>;
  preventSubmit?: boolean;
  confirmChanges?: boolean;
}

const FormWrapper: FC<FormWrapperProps> = ({
  form,
  schema,
  children,
  preventNavigationIfDirty = false,
  confirmChanges = false,
  initialData,
}: FormWrapperProps) => {
  const { isDirty, touchedFields } = form.formState;
  const shouldPreventNavigation =
    preventNavigationIfDirty &&
    (isDirty || Object.keys(touchedFields).length > 0);
  const { nextLocation, handleNavigationConfirm, handleNavigationCancel } =
    useNavigationBlocker(shouldPreventNavigation);
  const [showConfirmChanges, setShowConfirmChanges] = useState(false);

  useBeforeUnloadHandler(shouldPreventNavigation);

  // Add wrapper for the form element to prevent submission
  const wrappedChildren = React.cloneElement(children, {
    onSubmit: async (e: React.FormEvent) => {
      e.preventDefault();

      if (confirmChanges) {
        setShowConfirmChanges(true);
        return;
      }

      if (children.props.onSubmit) {
        await children.props.onSubmit(e);
      }
    },
  });

  const handleConfirmChanges = async () => {
    if (children.props.onSubmit) {
      await children.props.onSubmit();
    }
    setShowConfirmChanges(false);
  };

  const handleCancelChanges = () => {
    setShowConfirmChanges(false);
  };

  return (
    <ValidationSchemaProvider schema={schema}>
      {shouldPreventNavigation && (
        <UnsavedChangesModal
          isOpen={Boolean(nextLocation && shouldPreventNavigation)}
          onConfirm={handleNavigationConfirm}
          onCancel={handleNavigationCancel}
        />
      )}

      <FormProvider {...form}>
        {confirmChanges && (
          <ConfirmChangesModal
            isOpen={showConfirmChanges}
            onConfirm={handleConfirmChanges}
            onCancel={handleCancelChanges}
            initialValues={initialData ?? {}}
          />
        )}
        {wrappedChildren}
      </FormProvider>
    </ValidationSchemaProvider>
  );
};

export default FormWrapper;
