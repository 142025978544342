/* tslint:disable */
/* eslint-disable */
/**
 * MAC API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { ApplicationRequestMetaResponseDto } from './ApplicationRequestMetaResponseDto';
import {
    ApplicationRequestMetaResponseDtoFromJSON,
    ApplicationRequestMetaResponseDtoFromJSONTyped,
    ApplicationRequestMetaResponseDtoToJSON,
} from './ApplicationRequestMetaResponseDto';
import type { OwnerDto } from './OwnerDto';
import {
    OwnerDtoFromJSON,
    OwnerDtoFromJSONTyped,
    OwnerDtoToJSON,
} from './OwnerDto';

/**
 * 
 * @export
 * @interface RequestDto
 */
export interface RequestDto {
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    requestId?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    entityId?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    entity?: RequestDtoEntityEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    reqType?: RequestDtoReqTypeEnum;
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    status?: RequestDtoStatusEnum;
    /**
     * 
     * @type {OwnerDto}
     * @memberof RequestDto
     */
    requestedBy?: OwnerDto;
    /**
     * 
     * @type {Date}
     * @memberof RequestDto
     */
    requestedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    requestComment?: string;
    /**
     * 
     * @type {Set<OwnerDto>}
     * @memberof RequestDto
     */
    assignedTo?: Set<OwnerDto>;
    /**
     * 
     * @type {Date}
     * @memberof RequestDto
     */
    actionedDate?: Date;
    /**
     * 
     * @type {string}
     * @memberof RequestDto
     */
    actionComment?: string;
    /**
     * 
     * @type {OwnerDto}
     * @memberof RequestDto
     */
    actionedBy?: OwnerDto;
    /**
     * 
     * @type {ApplicationRequestMetaResponseDto}
     * @memberof RequestDto
     */
    applicationMetaResponse?: ApplicationRequestMetaResponseDto;
}


/**
 * @export
 */
export const RequestDtoEntityEnum = {
    Application: 'APPLICATION',
    Platform: 'PLATFORM',
    Product: 'PRODUCT',
    Portfolio: 'PORTFOLIO'
} as const;
export type RequestDtoEntityEnum = typeof RequestDtoEntityEnum[keyof typeof RequestDtoEntityEnum];

/**
 * @export
 */
export const RequestDtoReqTypeEnum = {
    DataIssue: 'DATA_ISSUE',
    PlatformChange: 'PLATFORM_CHANGE',
    OwnerRequest: 'OWNER_REQUEST',
    Verification: 'VERIFICATION',
    AppOnboard: 'APP_ONBOARD'
} as const;
export type RequestDtoReqTypeEnum = typeof RequestDtoReqTypeEnum[keyof typeof RequestDtoReqTypeEnum];

/**
 * @export
 */
export const RequestDtoStatusEnum = {
    Pending: 'PENDING',
    Approved: 'APPROVED',
    Rejected: 'REJECTED',
    Actioned: 'ACTIONED'
} as const;
export type RequestDtoStatusEnum = typeof RequestDtoStatusEnum[keyof typeof RequestDtoStatusEnum];


/**
 * Check if a given object implements the RequestDto interface.
 */
export function instanceOfRequestDto(value: object): boolean {
    let isInstance = true;

    return isInstance;
}

export function RequestDtoFromJSON(json: any): RequestDto {
    return RequestDtoFromJSONTyped(json, false);
}

export function RequestDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): RequestDto {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'requestId': !exists(json, 'requestId') ? undefined : json['requestId'],
        'entityId': !exists(json, 'entityId') ? undefined : json['entityId'],
        'entity': !exists(json, 'entity') ? undefined : json['entity'],
        'reqType': !exists(json, 'reqType') ? undefined : json['reqType'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'requestedBy': !exists(json, 'requestedBy') ? undefined : OwnerDtoFromJSON(json['requestedBy']),
        'requestedDate': !exists(json, 'requestedDate') ? undefined : (new Date(json['requestedDate'])),
        'requestComment': !exists(json, 'requestComment') ? undefined : json['requestComment'],
        'assignedTo': !exists(json, 'assignedTo') ? undefined : (new Set((json['assignedTo'] as Array<any>).map(OwnerDtoFromJSON))),
        'actionedDate': !exists(json, 'actionedDate') ? undefined : (new Date(json['actionedDate'])),
        'actionComment': !exists(json, 'actionComment') ? undefined : json['actionComment'],
        'actionedBy': !exists(json, 'actionedBy') ? undefined : OwnerDtoFromJSON(json['actionedBy']),
        'applicationMetaResponse': !exists(json, 'applicationMetaResponse') ? undefined : ApplicationRequestMetaResponseDtoFromJSON(json['applicationMetaResponse']),
    };
}

export function RequestDtoToJSON(value?: RequestDto | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'requestId': value.requestId,
        'entityId': value.entityId,
        'entity': value.entity,
        'reqType': value.reqType,
        'status': value.status,
        'requestedBy': OwnerDtoToJSON(value.requestedBy),
        'requestedDate': value.requestedDate === undefined ? undefined : (value.requestedDate.toISOString()),
        'requestComment': value.requestComment,
        'assignedTo': value.assignedTo === undefined ? undefined : (Array.from(value.assignedTo as Set<any>).map(OwnerDtoToJSON)),
        'actionedDate': value.actionedDate === undefined ? undefined : (value.actionedDate.toISOString()),
        'actionComment': value.actionComment,
        'actionedBy': OwnerDtoToJSON(value.actionedBy),
        'applicationMetaResponse': ApplicationRequestMetaResponseDtoToJSON(value.applicationMetaResponse),
    };
}

